import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import moment from 'moment';

import 'bootstrap/dist/css/bootstrap.min.css';


function Receipt() {
  const location = useLocation();
  const [receipt, set_receipt] = useState("");
  
  // Parse the query parameters from the URL
  const queryParams = new URLSearchParams(location.search);
  const orderId = queryParams.get('order_id');

  const GET__RECEIPT = async () => {
    const requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    await fetch(`https://cp-api-demo.al-jariyah.com/admin/kempen/resit/${orderId}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log("Details Transaksi: ", result);
        set_receipt(result.data);
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    if (orderId) {
      GET__RECEIPT();
    }
  }, [orderId]);

  return (
    <div className="card">
      <div className="card-body mx-4">
        <div className="container">
          <p className="my-5 " style={{ fontSize: "30px" }}>
            Terima Kasih Kerana Menyumbang.
          </p>

          <div className="row">
            <ul className="list-unstyled">
              <li className="text-muted mt-1">
                <span className="text-black">Nama Penyumbang: </span>
                {receipt.campTransPayerName}
              </li>
              <li className="text-muted mt-1">
                <span className="text-black">No. Telefon Penyumbang: </span>
                {receipt.campTransPayerPhone}
              </li>
              <li className="text-muted mt-1">
                <span className="text-black">E-mel Penyumbang: </span>
                {receipt.campTransPayerEmail}
              </li>
            </ul>
            <hr />

            <div className="col-xl-10">
              <p>Tarikh Transaksi: {moment(receipt.campTransCreatedDate).format('DD MMM, YYYY')}</p>
            </div>
            <div className="col-xl-10">
              <p>No. Rujukan: {receipt.campTransRefNo}</p>
            </div>
            
            <hr />
          </div>

          <div className="row">
            <div className="col-xl-10">
              <p>Jenis Transaksi: {receipt.campTransPaymentChannel}</p>
            </div>
            <div className="col-xl-10">
              <p>Status Transaksi: {receipt.campTransStatus}</p>
            </div>
            
            <hr style={{ border: "2px solid black" }} />
          </div>

          <div className="row text-black">
            <div className="col-xl-12">
              <p className="float-end fw-bold">
                Jumlah Transaksi: RM {parseFloat(receipt.campTransAmount).toFixed(2)}
              </p>
            </div>
            <hr style={{ border: "2px solid black" }} />
          </div>

          {/* <div className="text-center" style={{ marginTop: "90px" }}>
            <a>
              <u className="text-info">Cetak resit</u>
            </a>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default Receipt;
