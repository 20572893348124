import React, { useState, useEffect } from 'react';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import dataBanner from '../assets/data/data-banner';
import dataManfaat from '../assets/data/dataManfaat';
import dataPerkhidmatan from '../assets/data/dataPerkhidmatan';
import Manfaat from '../components/banner/ManfaatAJ';
import Banner01 from '../components/banner/Banner01';
import CTA from '../components/cta';
import PerkhidmatanKami from '../components/liveautions/PerkhidmatanKami';
import HomePopup from '../components/popup/HomePopup';
import samplePDF from '../assets/images/Risalah-Al-Jariyah2.pdf';
import samplePDF2 from '../assets/images/Brochure.pdf';

function Home01(props) {
    const [isPopupOpen, setIsPopupOpen] = useState(false); // Initially set to false
    const [isSecondPopupOpen, setIsSecondPopupOpen] = useState(false); 

    // UseEffect to trigger the first popup opening with delay
    useEffect(() => {
        const timer = setTimeout(() => {
            setIsPopupOpen(true); // Open the first popup after 1 second
        }, 1000);

        return () => clearTimeout(timer); // Cleanup on component unmount
    }, []);

    const handleClosePopup = () => {
        setIsPopupOpen(false);
        setTimeout(() => {
            setIsSecondPopupOpen(true); // Open the second popup after 1 second
        }, 1000);
    };

    const handleCloseSecondPopup = () => setIsSecondPopupOpen(false); 

    return (
        <div className="home-1">
            <div id="page">
                {/* First Popup with 1 second delay */}
                <HomePopup
                    isOpen={isPopupOpen}
                    onClose={handleClosePopup}
                    content={
                        <div style={{ textAlign: 'center', width: '100%', height: '100%' }}>
                            <Worker  workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}>
                                <Viewer fileUrl={samplePDF} />
                            </Worker>
                        </div>
                    }
                />

                {/* Second Popup */}
                <HomePopup
                    isOpen={isSecondPopupOpen}
                    onClose={handleCloseSecondPopup}
                    content={
                        <div style={{ textAlign: 'center', width: '100%', height: '100%' }}>
                            <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}>
                                <Viewer fileUrl={samplePDF2} />
                            </Worker>
                        </div>
                    }
                />

                <Banner01 data={dataBanner} />
                <PerkhidmatanKami data={dataPerkhidmatan} />
                <Manfaat data={dataManfaat} />
                <CTA />
            </div>
        </div>
    );
}

export default Home01;
