import React , {useState, useEffect } from 'react';
import {  useLocation, useNavigate, useParams } from 'react-router-dom';
import PageTitle from '../components/pagetitle/PageTitle2';
import { Link } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import avtd6 from '../assets/images/contactIcon/address.png'
import Data from '../assets/data/data-perintis'


import avtd3 from '../assets/images/contactIcon/mail.png'
import avtd4 from '../assets/images/contactIcon/profile.png'
import avtd5 from '../assets/images/contactIcon/tel.png'
import DefaultImage from "../assets/images/logo/logo_dark.png"

import DefaultImageKempen from "../assets/images/kempenDefault.jpg"



import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';


InstitusiDetaisl.propTypes = {
    
};

function InstitusiDetaisl(props) {

    const { slug }                                                          = useParams();
    const [itemData, setItemData]                                           = useState(null);
    const navigate                                                          = useNavigate()
    const state                                                             = useLocation().state

    const [activeTabIndex, setActiveTabIndex]                               = useState(0);

    const [nama_masjid, set_nama_masjid]                                    = useState("")
    const [org_code, set_org_code]                                          = useState("")
    const [gambar_masjid, set_gambar_masjid]                                = useState("")
    const [alamat_masjid, set_alamat_masjid]                                = useState("")
    const [bandar_masjid, set_bandar_masjid]                                = useState("")
    const [email_masjid, set_email_masjid]                                  = useState("")
    const [emailpic_masjid, set_emailpic_masjid]                            = useState("")
    const [namepic_masjid, set_namepic_masjid]                              = useState("")
    const [nomborpic_masjid, set_nomborpic_masjid]                          = useState("")
    const [phone_masjid, set_phone_masjid]                                  = useState("")
    const [description_masjid, set_description_masjid]                      = useState([])

    const [senarai_hebahan, set_senarai_hebahan]                            = useState([])
    const [senarai_kempen, set_senarai_kempen]                              = useState([])
    const [senarai_ajk, set_senarai_ajk]                                    = useState([])



    const DETAILS_MASJID = async () => {
        const requestOptions = {
            method: "GET",
            redirect: "follow"
          };
          
          await fetch(`https://demo-api.al-jariyah.com/api/portal/senarai-institusi/${state.organizationId}`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                //console.log("Details Institusi",result)
                let { 
                    organizationName, 
                    organizationImage, 
                    organizationAddress,
                    organizationCity,
                    organizationEmail,
                    organizationPICEmail,
                    organizationPICName,
                    organizationPICPhone,
                    organizationPhone,
                    organizationPostcode,
                    organizationState,
                    organizationData,
                    organizationCode
                    
                    
                } = result.data[0]
                set_nama_masjid(organizationName)
                set_gambar_masjid(organizationImage)
                set_alamat_masjid(organizationAddress)
                set_bandar_masjid(organizationCity)
                set_email_masjid(organizationEmail)
                set_emailpic_masjid(organizationPICEmail)
                set_namepic_masjid(organizationPICName)
                set_nomborpic_masjid(organizationPICPhone)
                set_phone_masjid(organizationPhone)
                set_description_masjid(organizationData)
                set_org_code(organizationCode)
            })
            
            .catch((error) => console.error(error));
    }

    const LIST_HEBAHAN = async () => {
        const requestOptions = {
            method: "GET",
            redirect: "follow",
        };
    
        await fetch(`https://cp-api-demo.al-jariyah.com/admin/getHebahan?orgId=${state.organizationId}`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                //console.log("List Hebahan: ", result);
                const activeHebahan = result.data.filter(item => item.HEB_STATUS === "Active");
                set_senarai_hebahan(activeHebahan);
            })
            .catch((error) => console.error(error));
    };
    
    

    const LIST_KEMPEM = async () => {
        const requestOptions = {
            method: "GET",
            redirect: "follow"
          };
          
          
          await fetch(`https://cp-api-demo.al-jariyah.com/admin/getKempen?orgId=${state.organizationId}`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                //console.log("List Kempen: ",result)
                const activeKempen = result.data.filter(item => item.campaignStatus === "ACTIVE");
                set_senarai_kempen(activeKempen)
            })
            
            .catch((error) => console.error(error));
    }

    const rolePriority = {
        "Pengerusi": 1,
        "Timbalan Pengerusi": 2,
        "Nazir": 3,
        "Timbalan Nazir": 4,
        "Setiausaha": 5,
        "Bendahari": 6,
        "Imam 1": 7,
        "Imam 2": 8,
        "Imam 3": 9,
        "Imam 4": 10,
        "Imam 5": 11,
        "Bilal 1": 12,
        "Bilal 2": 13,
        "Bilal 3": 14,
        "Bilal 4": 15,
        "Siak 1": 16,
        "Siak 2": 17,
        "Penasihat Agama": 18,
        "Penasihat Am": 19,
        "Pemeriksa Kira-Kira 1": 20,
        "Pemeriksa Kira-Kira 2": 21,
        "AJK 1": 22,
        "AJK 2": 23,
        "AJK 3": 24
    };

    const LIST_AJK = async () => {
        const requestOptions = {
            method: "GET",
            redirect: "follow"
        };
        
        await fetch(`https://cp-api-demo.al-jariyah.com/admin/getSubUsersByOrganization?orgId=${state.organizationId}`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
    
                const roleOrder = ["Pengerusi","Timbalan Pengerusi","Nazir","Timbalan Nazir","Setiausaha","Bendahari","Imam 1","Imam 2","Imam 3","Imam 4","Imam 5","Bilal 1","Bilal 2","Bilal 3","Bilal 4","Siak 1","Siak 2","Penasihat Agama","Penasihat Am","Pemeriksa Kira-Kira 1","Pemeriksa Kira-Kira 2","AJK 1","AJK 2","AJK 3"];
    
                const activeAJK = result.data
                    .filter(item => item.AjkStatus === "Active")
                    .sort((a, b) => roleOrder.indexOf(a.role) - roleOrder.indexOf(b.role));

                    //console.log("List AJK Active",activeAJK);
    
                set_senarai_ajk(activeAJK);
            })
            .catch((error) => console.error(error));
    };
    
    
    
    useEffect(() => {
        DETAILS_MASJID()
        LIST_HEBAHAN()
        LIST_AJK()
        LIST_KEMPEM()
    }, [])

    

    useEffect(() => {
        //console.log('useEffect triggered');
        const itemData = Data.find((item) => item.slug === slug);
        //console.log(itemData);
        setItemData(itemData);
      }, [slug]);
    
    //   if (!itemData) {
    //     return <div>{JSON.stringify(state)}</div>;
    //   }

    return (
        <div>

            <PageTitle sub='Senarai Institusi' title='Maklumat Institusi' />

            <section className="tf-item-detail">
                <div className="tf-container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="tf-item-detail-inner">
                                <div className="image" style={{ width: "400px", height: "300px", position: "relative", paddingRight:"15px" }}>
                                    <img 
                                        src={gambar_masjid ? gambar_masjid : DefaultImage} 
                                        alt="AlJariyah" 
                                        style={{ 
                                            maxWidth: "100%",      
                                            maxHeight: "100%",
                                            objectFit: "contain",
                                            border: "groove" 
                                        }} 
                                    />
                                </div>

                                <div className="content">
                                    
                                    <h2 className="title-detail">{nama_masjid}</h2>

                                    <Tabs className="tf-tab" selectedIndex={activeTabIndex} onSelect={(index) => setActiveTabIndex(index)}>
                                        <TabList className="menu-tab">
                                            <Tab className={`tab-title ${activeTabIndex === 0 ? 'active' : ''}`}>
                                                <Link to="#">Maklumat</Link>
                                            </Tab>
                                            <Tab className={`tab-title ${activeTabIndex === 1 ? 'active' : ''}`}>
                                                <Link to="#">Pegawai Untuk Dihubungi</Link>
                                            </Tab>
                                            <Tab className={`tab-title ${activeTabIndex === 2 ? 'active' : ''}`}>
                                                <Link to="#">Alamat</Link>
                                            </Tab>
                                        </TabList>

                                        <TabPanel>
                                            <div className="tab-details">
                                                <div className="top">
                                                    <div className="">
                                                        {description_masjid && description_masjid.length > 0 ? (
                                                            description_masjid.map((data, index) => (
                                                                <div key={index} className="mb-5">
                                                                    <div className="box-bid">
                                                                        <div className="image-bid">
                                                                            {/* Image or any other content */}
                                                                        </div>
                                                                        <div className="">
                                                                            <p className="h3 font-weight-bold">
                                                                                {data.title ?? '-- tiada maklumat --'}
                                                                            </p>
                                                                            <div className="time">
                                                                                {data.data ?? '-- tiada maklumat --'}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))
                                                        ) : (
                                                            <p style={{ textAlign: 'justify', fontSize: '18px', color: '#888' }}>
                                                                Tiada Rekod
                                                            </p>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </TabPanel>

                                        <TabPanel>
                                            <ul className="tab-bid">
                                                <li>
                                                    <div className="box-bid">
                                                        <div className="image-bid">
                                                            <img src={avtd4} alt="AlJariyah" />
                                                        </div>
                                                        <div className="infor">
                                                            <div className="history"> <span className="name">Nama Pegawai</span></div>
                                                            <div className="time">{namepic_masjid ?? '-- tiada maklumat --'}</div>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="box-bid">
                                                        <div className="image-bid">
                                                            <img src={avtd5} alt="AlJariyah" />
                                                        </div>
                                                        <div className="infor">
                                                            <div className="history"> <span className="name">Nombor Telefon</span></div>
                                                            <div className="time">{nomborpic_masjid ?? '-- tiada maklumat --'}</div>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="box-bid">
                                                        <div className="image-bid">
                                                            <img src={avtd3} alt="AlJariyah" />
                                                        </div>
                                                        <div className="infor">
                                                            <div className="history"> <span className="name">E-mel</span></div>
                                                            <div className="time">{emailpic_masjid ?? '-- tiada maklumat --'}</div>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </TabPanel>

                                        <TabPanel>
                                            <ul className="tab-bid">
                                                <li>
                                                    <div className="box-bid">
                                                        <div className="image-bid">
                                                            <img src={avtd6} alt="AlJariyah" />
                                                        </div>
                                                        <div className="infor">
                                                            <div className="history"> <span className="name">{bandar_masjid ?? '-- tiada maklumat --'}</span></div>
                                                            <div className="time">{alamat_masjid ?? '-- tiada maklumat --'}</div>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </TabPanel>
                                    </Tabs> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="tf-section tf-top-seller">
                <div className="tf-container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="tf-heading style-3 mb40 wow fadeInUp">
                                <h5 className="heading">Senarai Ahli Jawatankuasa</h5>
                            </div>
                        </div>

                        {!senarai_ajk || senarai_ajk.length === 0 ? (
                            <div className="col-md-12">
                                <p style={{ textAlign: 'justify', fontSize: '18px', color: '#888' }}>
                                    Tiada Rekod
                                </p>
                            </div>
                        ) : (
                            senarai_ajk.map((jawatan,idx) => (
                                <div key={idx} className="col-xl-3 col-lg-4 col-md-6">
                                    <div className="tf-author-wrap">
                                        <div className="tf-author" style={{ background: "white", border: "1px solid #E5E5E5" }}>
                                            <div className="content">
                                                <div className="title">
                                                    <p className="h4 font-weight-bold" >{jawatan.role}</p>
                                                </div>
                                                <div className="price">
                                                    <span className="price-eth">{jawatan.username}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        )}
                    </div>
                </div>
            </section>


            <section className="tf-section tf-news-events" style={{ paddingBottom: "90px" }}>
                <div className="tf-container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="tf-heading style-3 mb40 wow fadeInUp">
                                <h5 className="heading">Hebahan Institusi</h5>
                            </div>
                        </div>
                        <div className="col-md-12">
                            {!senarai_hebahan || senarai_hebahan.length === 0 ? (
                                <p style={{ textAlign: "justify", color: "#888", fontSize: "18px" }}>
                                    Tiada Rekod
                                </p>
                            ) : (
                                <Swiper
                                    modules={[Navigation, Pagination, Scrollbar, A11y]}
                                    spaceBetween={30}
                                    slidesPerView={3}  
                                    navigation
                                    pagination={{ clickable: true }}
                                    breakpoints={{
                                        1024: {
                                            slidesPerView: 3,
                                        },
                                        768: {
                                            slidesPerView: 2,
                                        },
                                        0: {
                                            slidesPerView: 1,
                                        },
                                    }}
                                    // onSwiper={(swiper) => console.log(swiper)}
                                    // onSlideChange={() => console.log('slide change')}
                                >
                                    {senarai_hebahan.map((newsItem, idx) => (
                                        <SwiperSlide key={idx}>
                                            <div className="news-card" style={{ height: '400px', display: 'flex', flexDirection: 'column' }}>
                                                <div style={{ width: '100%', height: '200px', overflow: 'hidden' }}>
                                                    <img
                                                        src={newsItem.HEB_IMAGE}
                                                        alt={newsItem.HEB_TITLE}
                                                        style={{
                                                            width: '100%',
                                                            height: '100%',
                                                            objectFit: 'fill',
                                                        }}
                                                    />
                                                </div>
                                                <div className="news-content" style={{paddingTop:"30px",}}>
                                                    <h6 className="news-title truncate">{newsItem.HEB_TITLE}</h6>
                                                    <p className="news-description truncate" style={{paddingBottom:"20px"}}>{newsItem.HEB_DESCRIPTION}</p>
                                                    <button
                                                        className="tf-button"
                                                        onClick={() =>
                                                            navigate(`/institusi/hebahan/${newsItem.HEB_TITLE.trim().replace(/\s/g, "-")}`, { state: newsItem })
                                                        }
                                                    >
                                                        Maklumat Lanjut
                                                    </button>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            )}
                        </div>
                    </div>
                </div>
            </section>


            <section className="tf-section tf-news-events" style={{ paddingBottom: "90px" }}>
                <div className="tf-container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="tf-heading style-3 mb40 wow fadeInUp">
                                <h5 className="heading">Kempen Institusi</h5>
                            </div>
                        </div>
                        <div className="col-md-12">
                            {!senarai_kempen || senarai_kempen.length === 0 ? (
                                <p style={{ textAlign: "justify", color: "#888", fontSize: "18px" }}>
                                    Tiada Rekod
                                </p>
                            ) : (
                                <Swiper
                                    modules={[Navigation, Pagination, Scrollbar, A11y]}
                                    spaceBetween={50}
                                    slidesPerView={3}
                                    navigation
                                    pagination={{ clickable: true }}
                                    breakpoints={{
                                        1024: {
                                            slidesPerView: 3,
                                        },
                                        768: {
                                            slidesPerView: 2,
                                        },
                                        0: {
                                            slidesPerView: 1,
                                        },
                                    }}
                                >
                                    {senarai_kempen.map((kempenItem, idx) => (
                                            <SwiperSlide key={idx}>
                                                <div className="news-card" style={{ height: '400px', display: 'flex', flexDirection: 'column' }}>
                                                    <div style={{ width: '100%', height: '200px', overflow: 'hidden' }}>
                                                        <img
                                                            src={kempenItem.campaignImage ? kempenItem.campaignImage : DefaultImageKempen}
                                                            alt={kempenItem.campaignTitle}
                                                            onError={(e) => {
                                                                e.target.onError = null;
                                                                e.target.src = DefaultImageKempen;
                                                            }}
                                                            style={{
                                                                width: '100%',
                                                                height: '100%',
                                                                objectFit: 'fill'
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="news-content" style={{ paddingTop: '30px' }}>
                                                        <h6 className="news-title">{kempenItem.campaignTitle}</h6>
                                                        <p className="news-description" style={{paddingBottom:"20px"}}>Sasaran Kutipan: RM {kempenItem.campaignTarget}</p>
                                                        <button className="tf-button" onClick={() => navigate(`/institusi/kempen?orgCode=${org_code}&campaignId=${kempenItem.campaignId}&campaignCode=${kempenItem.campaignCode}`, { state: kempenItem })}>
                                                            Maklumat Lanjut
                                                        </button>
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                        ))}
                                </Swiper>
                            )}
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default InstitusiDetaisl;